import React from 'react';

// Layout import
import Layout from '../layouts/main';

// SEO imports
import SEO from '../components/seo';
import ogImage from '../assets/images/og-images/southern-edge-communications.jpg';

// Componenent imports
import Intro from '../components/intro';

// Intro props
let introProps = {
	title: 'Thank You!',
	description: "Thanks for submitting our form! We'll be in touch!",
	cta: 'Back to Home',
	link: '/'
};

const ThankYou = () => (
	<Layout>
		<SEO title="Thank You" description="Southern Edge Communications" image={ogImage} />
		<Intro {...introProps} />
	</Layout>
);

export default ThankYou;
